/**
 * This component will be used for the Jump Back In effort on the homepage.
 *
 * @module views/components/CardsRail
 * @memberof -Common
 */
import './CardsRail.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React from 'react';

import PropTypes from 'prop-types';
import SwiperCore, {
  A11y,
  Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Button from '@ulta/core/components/Button/Button';
import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Text from '@ulta/core/components/Text/Text';

import carouselUtils, { handleInactiveSwiperSlides } from '@ulta/utils/carouselUtils/carouselUtils';

import ImagesGridCard from '../ImagesGridCard/ImagesGridCard';


// Install Swiper modules
SwiperCore.use( [Navigation, Keyboard, A11y] );
let swiperCounter = 1;

/**
 * Represents a CardsRail component
 *
 * @method
 * @param {CardsRailProps} props - React properties passed from composition
 * @returns CardsRail
 */
export const CardsRail = ( props ) => {
  const { title, subTitle, modules } =  props;
  swiperCounter += 1;

  if( modules?.length === 0 ){
    return null;
  }

  const { carouselDetailsAccessibilityLabel, carouselAccessibilityLabel } = {};

  return (
    <div className='CardsRail'>
      <GridContainer>
        <div className='CardsRail__Content'>
          <div className='CardsRail__header'>
            { title && <Text
              textStyle='title-6'
              htmlTag={ title?.htmlTag || 'h3' }
            >
              { title?.text }
            </Text>
            }
            { subTitle && (
              <div className='CardsRail__Subtitle'>
                <Text
                  htmlTag={ subTitle?.htmlTag || 'p' }
                  textStyle='body-2'
                  color='neutral-500'
                >
                  { subTitle?.text }
                </Text>
              </div>
            ) }
          </div>
          <div className='CardsRail__Container'
            role='region'
            aria-label={ carouselUtils.handleTokenizedString( carouselAccessibilityLabel, [title?.text] ) }
          >
            <p className='sr-only'>{ carouselUtils.handleTokenizedString( carouselDetailsAccessibilityLabel, [title?.text] ) }</p>
            <div className={ `CardsRail__Pagination CardsRail__Pagination-${swiperCounter}` }>
              <Button
                variant='navigation'
                iconImage='CaretBack'
                iconSize='lg'
                className='CardsRail__Pagination-button CardsRail__Pagination-button--back'
                ariaHiddenIcon={ true }
              />
              <Button
                variant='navigation'
                iconImage='CaretForward'
                iconSize='lg'
                className={ 'CardsRail__Pagination-button CardsRail__Pagination-button--forward' }
                ariaHiddenIcon={ true }
              />
            </div>
            <div className='CardsRail__rail-swiper-container'>
              <Swiper
                preloadImages
                speed={ 600 }
                spaceBetween={ 8 }
                navigation={ {
                  prevEl: `.CardsRail__Pagination-${swiperCounter} .CardsRail__Pagination-button--back`,
                  nextEl: `.CardsRail__Pagination-${swiperCounter} .CardsRail__Pagination-button--forward`,
                  disabledClass: 'CardsRail__Pagination--disabled',
                  hiddenClass: 'CardsRail__Pagination--hidden'
                } }
                freeMode={ {
                  minimumVelocity: 0.01,
                  momentum: true,
                  momentumBounce: true,
                  momentumBounceRatio: 1.2,
                  momentumRatio: 1.2,
                  momentumVelocityRatio: 1.2,
                  sticky: true
                } }
                breakpoints={
                  {
                    1024: {
                      slidesPerView: 4,
                      slidesPerGroup: 4
                    },
                    768: {
                      slidesPerView: 3,
                      slidesPerGroup: 4
                    },
                    375: {
                      slidesPerView: 1.5,
                      slidesPerGroup: 4
                    }
                  }
                }
                watchOverflow={ true }
                watchSlidesProgress={ true }
                watchSlidesVisibility={ true }
                onSwiper={ ( swiper ) => handleInactiveSwiperSlides( swiper ) }
                // setTimeout is needed to ensure swiper is fully initialized because asyncComponent is loaded after swiper
                onSlidesLengthChange={ ( swiper ) => setTimeout( () => handleInactiveSwiperSlides( swiper ), 0 ) }
                onSlideChange={ ( swiper ) => handleInactiveSwiperSlides( swiper ) }
              >
                {
                  modules?.map( ( card, index ) => (
                    <SwiperSlide key={ index }>
                      <ImagesGridCard
                        { ...card }
                      />
                    </SwiperSlide>
                  ) )
                }
              </Swiper>
            </div>
          </div>
        </div>
      </GridContainer>
    </div>
  );

};

/**
 * Property type definitions
 * @typedef CardsRailProps
 * @type {object}
 * @property {object} title - Label for title
 * @property {object} subTitle - Label for subTitle
 * @property {array} modules - Array of modules in the rail
 * @property {object} railADAMeta - Aria labels for the rail
 */
export const propTypes = {
  title: PropTypes.object,
  subTitle: PropTypes.object,
  modules: PropTypes.arrayOf(
    PropTypes.shape( {
      title: PropTypes.object,
      subTitle: PropTypes.string,
      images: PropTypes.arrayOf( PropTypes.object ),
      action: PropTypes.object
    } )
  ),
  railADAMeta: PropTypes.shape( {
    carouselDetailsAccessibilityLabel:PropTypes.string,
    carouselAccessibilityLabel: PropTypes.string
  } )
};

CardsRail.propTypes = propTypes;

export default CardsRail;
