/**
 * A simple card that contains a title, subtitle, and a maximum of 3 product images.
 *
 * @module views/Organisms/ImagesGridCard
 * @memberof -Common
 */
import './ImagesGridCard.scss';

import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import Image from '@ulta/core/components/Image/Image';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import { useIntersectionProcessor } from '@ulta/core/hooks/useIntersectionProcessor/useIntersectionProcessor';

import Text from '../Text/Text';

/**
 * Represents a ImagesGridCard component
 *
 * @method
 * @param {ImagesGridCardProps} props - React properties passed from composition
 * @returns ImagesGridCard
 */
export const ImagesGridCard = ( props ) => {
  const imagesGridCardRef = useRef( null );
  const { title, subTitle, images = [], action } =  props;

  const imagesToDisplay = images.length > 3 ? images.slice( 0, 3 ) : images;
  useIntersectionProcessor( {
    ...props,
    componentRef: imagesGridCardRef
  } );

  if( !images.length || images.some( ( image ) => !image.imageUrl ) ){
    return null;
  }

  return (
    <div className='ImagesGridCard'
      ref={ imagesGridCardRef }
    >
      <Link_Huge url={ action?.url }
        action={ action }
        unstyled={ true }
      >
        <div className='ImagesGridCard__headline'>
          { title && <Text
            htmlTag={ title?.htmlTag }
            textStyle='title-sm'
            fontWeight='500'
          >
            { title?.text }
          </Text> }
          { subTitle && <div className='ImagesGridCard__subtitle'>
            <Text
              htmlTag='p'
              textStyle='body-3'
              color='neutral-500'
            >
              { subTitle }
            </Text>
          </div> }
        </div>
        <ul className='ImagesGridCard__product-images'>
          { imagesToDisplay.map( ( image, index ) => {
            return (
              <li key={ index }
                className='ImagesGridCard__product-image'
              >
                <Image
                  alt={ image.accessibilityLabel }
                  src={ image.imageUrl }
                  metaData={ {
                    imageTemplate: 'Neutral00BG'
                  } }
                  shouldUseIntersectionObserver={ false }
                />
              </li>
            );
          }
          ) }
        </ul>
      </Link_Huge>
    </div>
  );
};

/**
  * Property type definitions
  * @typedef ImagesGridCardProps
  * @type {object}
  * @property {object} title - Label for title
  * @property {string} subTitle - Label for subTitle
  * @property {array} images - Array of image objects
  * @property {object} action - Action from DXL
  */
export const propTypes = {
  title: PropTypes.object.isRequired,
  subTitle: PropTypes.string,
  images: PropTypes.arrayOf( PropTypes.shape( {
    imageUrl: PropTypes.string,
    accessibilityLabel: PropTypes.string
  } ) ),
  action: PropTypes.shape( {
    url: PropTypes.string
  } ).isRequired
};

ImagesGridCard.propTypes = propTypes;

export default ImagesGridCard;
